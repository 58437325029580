import React, { useEffect } from 'react'
import { graphql, useStaticQuery, Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import Layout from '../../components/layout'
import Seo from '../../components/seo'
import Footer from '../../components/Footer'
import { SocialMediaLinks } from '../../utils'
import './page-events.css'


const PageEvents = ({ pageContext, location }) => {
    const page = pageContext.page;
    const dktEvents = pageContext.dktEvents;

    const data = useStaticQuery(graphql`
    query {
        eventsLogo: file(relativePath: {eq: "premiere_gallery_logo.png"}) {
            id
            childImageSharp {
                gatsbyImageData(
                    width: 480
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                )
                original {
                    src
                }
            }
        }
        eventsPeople: file(relativePath: { eq: "people.png" }) {
            id
            childImageSharp {
                gatsbyImageData(
                    width: 1920
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                )
            }
        }
        eventsWildSide: file(relativePath: { eq: "gallery-the-wild-side.png" }) {
            id
            childImageSharp {
                gatsbyImageData(
                    width: 480
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                )
            }
        }
        mask: file(relativePath: { eq: "gallery-mask.png" }) {
            id
            publicURL
        }
    }`)
   
    const imgStyle = {
        WebkitMaskImage: `url(${data.mask.publicURL})`,
        WebkitMaskSize: 'contain',
        WebkitMaskPosition: 'center',
        WebkitMaskRepeat: 'no-repeat',
        maskImage: `url(${data.mask.publicURL})`,
        maskSize: 'contain',
        maskPosition: 'center',
        maskRepeat: 'no-repeat',
        width:'300px',
        objectFit:'cover',
        margin: 'auto'
    }

    useEffect(() => {
        if (typeof document !== `undefined` && typeof window !== `undefined`) {
            document.addEventListener('scroll', function() {
                let rateY = window.pageYOffset * 0.6;
                rateY = isNaN(rateY) ? 0 : rateY;
                let elm = document.getElementById('event-logo');
                if (elm) elm.style.transform = 'translate3d(0,'+ rateY +'px,0)';
            });
        }
    })
    
    
    return (
        <Layout noFooter={true}>
            <Seo title={page.title} meta={[
                { property:'og:url', content:location.href }
            ].concat(SocialMediaLinks(data.favicon?.childImageSharp.original.src))}/>

            <div id="event-logo">
            <div className="grid content-center mt-10 overflow-hidden" style={{minHeight:'43vh'}} >
                <div className="flex items-center ">
                    <GatsbyImage image={data.eventsLogo.childImageSharp.gatsbyImageData} 
                        style={{width:'100%', maxWidth:'480px', margin:'auto'}} alt="PREMIERE Condoms Events Logo"/>
                </div>
            </div>
            </div>

            <div className="relative" style={{zIndex:999}}>
                <GatsbyImage image={data.eventsPeople.childImageSharp.gatsbyImageData} alt="People shadows"/>
                <div className="absolute flex items-center transform -translate-x-1/2 top-3/4 left-1/2" style={{maxWidth:'90%', width:'600px'}}>
                    <GatsbyImage image={data.eventsWildSide.childImageSharp.gatsbyImageData} className="m-auto" alt="Walk on the wild side"/>
                </div>
            </div>

            <div className="relative pt-10 bg-black">
                <div className="mx-auto my-container">

                    <div className="flex flex-wrap items-center justify-center gap-4 px-4 sm:grid-cols-2 md:grid-cols-2">
                        {dktEvents.map((dktEvent) => (
                            <Link key={dktEvent.postId} className="flex flex-col mb-10" to={`/event/${dktEvent.postSlug}`} style={{width:'500px', minWidth:'300px', minHeight:'300px'}}>
                                <GatsbyImage image={dktEvent.imageFile.childImageSharp.gatsbyImageData} className="block m-auto event-item-filter" style={imgStyle} alt={dktEvent.postTitle}/>
                                <p className="block text-lg text-center text-white event-item-name hover:text-yellow-400">
                                    {dktEvent.postTitle}
                                </p>
                            </Link>
                        ))}
                    </div>
                </div>
                <Footer noIconsInvert={true}/>
            </div>
            
        </Layout>
    )
}

export default PageEvents;